import { ref } from '@vue/composition-api'
import store from '@/store'

export const useRemoteData = () => {
  const submittingFetch = ref(false)
  const users = ref([])

  const fetchServiceProviders = (...args) => {
    submittingFetch.value = true
    return store.dispatch('user/fetchServiceProviders', ...args).then(response => {
      submittingFetch.value = false
      return response
    })
  }

  return {
    users,
    submittingFetch,
    fetchServiceProviders,
  }
}

export const useFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    page: 1,
    perPage: 6,
    isSortDirDesc: true,
    pointPurposes: [],
    userId: null,
    postStatuses: [],
    languages: [],
    locationRadius: 25000,
    lat: '',
    lng: '',
    geolocationIsActive: true,
    managed: null,
    referral: null,
  })

  const filterOptions = {
    perPage: [6, 9, 24, 72],
    pointPurposes: [],
    useId: null,
    postStatuses: [],
    languages: [],
  }

  // Sorting
  const sortBy = ref({ text: 'Sort by Date', value: 'update_date' })
  const sortByOptions = [
    { text: 'Sort by Date', value: 'update_date' },
    // { text: 'Estimated time of stay', value: 'estimated_time_of_stay' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useUi = () => {
  const itemView = ref('map-view')
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    // { icon: 'ListIcon', value: 'list-view' },
    { icon: 'MapIcon', value: 'map-view' },
  ]

  // Pagination count <= required by pagination component
  const totalUsers = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalUsers,
  }
}
