var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{ref:"pointPurposes",attrs:{"id":"pointPurposes","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.$t('point.which_service_do_you_require?'),"label":"item_data","multiple":"","filterable":false,"loading":_vm.fetchingPointPurposes,"selectable":function () { return _vm.filters.pointPurposes.length < 3; },"options":_vm.optionsPointPurposes},on:{"search":function (search, loading) {
      if(search.length > 3){
        loading(true)
        _vm.onSearchDebounced(search, loading)}
    }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
    var title = ref.title;
    var point_type = ref.point_type;
return [_c('div',{staticClass:"text-wrap"},[_c('strong',[_vm._v(" "+_vm._s(title))])]),_c('small',[_vm._v(" ("+_vm._s(_vm.getTypeTitle(point_type.slug))+")")])]}},{key:"selected-option",fn:function(ref){
    var title = ref.title;
return [_c('div',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(title)+" ")])]}},{key:"no-options",fn:function(ref){
    var search = ref.search;
    var searching = ref.searching;
    var loading = ref.loading;
return [_c('span'),(search.length && !loading)?_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('common.no_results_found_for'))+": "+_vm._s(search))]):_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t('common.start_typing_to_search')))])]}}]),model:{value:(_vm.pointPurposes),callback:function ($$v) {_vm.pointPurposes=$$v},expression:"pointPurposes"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }